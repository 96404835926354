<div class="projets-container">
    <mat-card class="card-projet" appearance="outlined" *ngFor="let p of ListeProjets; index as i">
        <a href="/projet/ {{ p.id }} /producteurs">
        <mat-card-header class="card-header-custom">
            <mat-icon class="header-icon">folder</mat-icon>
            <div class="header-text">
                <mat-card-title>Projet {{ p.id }}</mat-card-title>
                <mat-card-subtitle>Édité par editeur • dateEdition</mat-card-subtitle>
            </div>
            <mat-icon class="more-options">more_vert</mat-icon>
        </mat-card-header>
        <mat-card-content>
            <div class="icon-text">
                <mat-icon class="icon-circle">solar_power</mat-icon>
                <p>{{ p.participants }} Producteurs (capacité kWc)</p>
            </div>
            <div class="icon-text">
                <mat-icon class="icon-circle">power</mat-icon>
                <p>{{ p.nbConsommateurs }} Consommateurs</p>
            </div>
            <div class="icon-text">
                <mat-icon class="icon-circle">layers</mat-icon>
                <p>{{ p.scenarios }} Scénarios</p>
            </div>
        </mat-card-content>
        </a>
    </mat-card>
</div>
