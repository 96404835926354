import { Routes, mapToCanActivate } from '@angular/router';
import { hasAdminRoleGuard, hasNoValidTokenGuard, checkTokenValidityAndRefreshIfNeededGuard } from './Services/authentification.service';
import { ConnexionPage } from './Pages/connexion/connexion.component';
import { AccueilPage } from './Pages/accueil/accueil.component';
import { ProjetsPage } from './Pages/projets/projets.component';
import { CreationProjetPage } from './Pages/creation-projet/creation-projet.component';
import { ProjProducteursPage } from './Pages/proj-producteurs/proj-producteurs.component';
import { ProjConsommateursPage } from './Pages/proj-consommateurs/proj-consommateurs.component';
import { ProjConfigPage } from './Pages/proj-config/proj-config.component';
import { ProjScenariosPage } from './Pages/proj-scenarios/proj-scenarios.component';
import { ProjBilanPage } from './Pages/proj-bilan/proj-bilan.component';
import { MonComptePage } from './Pages/mon-compte/mon-compte.component';
import { TestsPage } from './Pages/tests/tests.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full',
    },
    {
        path: 'accueil',
        component: AccueilPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'connexion',
        component: ConnexionPage,
        canActivate: mapToCanActivate([hasNoValidTokenGuard])
    },
    {
        path: 'mon-compte',
        component: MonComptePage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'projets',
        component: ProjetsPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard]),
        children: [
            {
              path: 'creation',
              component: CreationProjetPage,
              canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard, hasAdminRoleGuard])
            }
          ]      
    },
    {
        path: 'creation-projet',
        component: CreationProjetPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard, hasAdminRoleGuard])
    },
    {
        path: 'projet/:idProjet/producteurs',
        component: ProjProducteursPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'projet/:idProjet/consommateurs',
        component: ProjConsommateursPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'projet/:idProjet/configuration',
        component: ProjConfigPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'projet/:idProjet/scenarios',
        component: ProjScenariosPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'projet/:idProjet/bilan',
        component: ProjBilanPage,
        canActivate: mapToCanActivate([checkTokenValidityAndRefreshIfNeededGuard])
    },
    {
        path: 'tests',
        component: TestsPage
    },
];
