import { Observable, map, firstValueFrom, catchError, of, BehaviorSubject } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../Environments/environment';

enum AppRoles {
  admin = 'ROLE_ADMIN',
  user = 'ROLE_USER'
}

type ApiAuthentificationResponse = {
  token: string;
  refresh_token: string;
}

type ApiRafraichissementTokenResponse = {
  token: string;
}

export type AuthTokenInfo = {
  exp: number;
  iat: number;
  logged: boolean;
  nom: string;
  prenom: string;
  username: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  tokenInfoSignal = signal<AuthTokenInfo | null>(null);
  headerTitreSignal = signal<string>('TECSOL PV');
  menuLateralSignal = signal<string>('lg');

  constructor(private router:Router, private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.getCurrentTokenInfo().then(tokenInfo => {
      this.tokenInfoSignal.set(tokenInfo)
    })
  }
  
  // ======================== Gestion du Token ==============================
  async getToken(): Promise<string | null> {
    //let storageResult: GetResult = await Preferences.get({key: 'token'});
    let storageResult = localStorage.getItem('token');
    return storageResult;
  }

  async isCurrentTokenValid(): Promise<boolean> {
    let currentTokenInfo = await this.getCurrentTokenInfo();

    // Si le token n'existe pas
    if (!currentTokenInfo) {
      return false;
    }

    // Si le token est à plus de 80% de la durée de vie
    let errorMargin = 0.8;
    let tokenDurationMs = (currentTokenInfo.exp - currentTokenInfo.iat) * 1000;
    let currentTimestampMs = (new Date()).getTime();
    let elapsedMsSinceCreation = currentTimestampMs - currentTokenInfo.iat * 1000;

    return tokenDurationMs * errorMargin > elapsedMsSinceCreation;

  }

  async getCurrentTokenInfo(): Promise<AuthTokenInfo | null> {
    let storageResult = localStorage.getItem('token');
    if (!storageResult) {
      return null; 
    }
    let currentTokenInfo = this.jwtHelper.decodeToken<AuthTokenInfo>(storageResult);
    return currentTokenInfo;
  }

  async removeToken(): Promise<void> {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    //await Preferences.remove({key : 'token');
    //await Preferences.remove({key : 'refresh_token'});
    //console.log("[tokenState] Mise à jour de l'état du token", null)
    this.tokenInfoSignal.set(null);
  }

  async setToken(accessToken: string, refreshToken?: string): Promise<void> {
    localStorage.setItem('token', accessToken);

    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }

    let currentTokenInfo = this.jwtHelper.decodeToken<AuthTokenInfo>(accessToken);
    //console.log("[tokenState] Mise à jour de l'état du token", currentTokenInfo)
    this.tokenInfoSignal.set(currentTokenInfo);
  }

  async forceTokenRefresh(): Promise<boolean> {
    // retourne un boolean qui indique si le token a pu être rafraichi correctement

    let storageResult = localStorage.getItem('refresh_token');
    let refreshTokenInStorage = storageResult;

    // check si le token est dans le stockage
    if (!refreshTokenInStorage) {
      await this.removeToken();
      return false; 
    }

    // appel l'api et récupère le résultat avec await
    let pipeline = this.apiRafraichissement(refreshTokenInStorage).pipe(
      catchError(_ => {
        return of(null)
      })
    );
    let response = await firstValueFrom(pipeline);

    // check si la réponse n'est pas 'null' pour savoir si il y a eu une erreur
    if (!response) {
      await this.removeToken();
      return false;
    }

    await this.setToken(response.token);
    return true;
  }

  logIn(login : string, passwd : string): Observable<void> {
    return this.apiAuthentification(login, passwd).pipe(
      map((data) => {
        if (! environment.production)
          console.log('Bearer '+data.token);
        this.setToken(data.token, data.refresh_token)
        .then(() => {
          this.router.navigateByUrl('/accueil');
        });
      })
    );
  }

  logOut(){
    this.removeToken()
    .then(_ => {
      this.router.navigateByUrl('/connexion');
    });
  }

  // ========================== Requêtes API ================================
  apiAuthentification(login: string, passwd: string) {
    let endpoint = `${environment.apiHost}api/login_check`;
    let postData = {username: login, password: passwd};
    return this.http.post<ApiAuthentificationResponse>(endpoint, postData);
  }

  apiRafraichissement(refreshToken: string) {
    let endpoint = `${environment.apiHost}api/token/refresh`;
    let postData = {refresh_token: refreshToken};
    return this.http.post<ApiRafraichissementTokenResponse>(endpoint, postData);
  }
}

// ========================== Route Guards ================================  

@Injectable({providedIn: 'root'})
export class hasNoValidTokenGuard {
  constructor(private auth: AuthentificationService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let isValid = await this.auth.isCurrentTokenValid();

    //console.log(`hasNoValidTokenGuard => navigation demandée vers ${state.url} (token valide: ${isValid})`, route, state);

    if (!isValid) {
      return true;
    } else {
      this.router.navigateByUrl('/accueil');
      return false;
    }
  }
}

@Injectable({providedIn: 'root'})
export class hasValidTokenGuard {
  constructor(private auth: AuthentificationService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isValid = await this.auth.isCurrentTokenValid();

    if (isValid) {
      return true;
    } else {
      this.router.navigateByUrl('/connexion');
      return false;
    }
  }
}

@Injectable({providedIn: 'root'})
export class checkTokenValidityAndRefreshIfNeededGuard {
  constructor(private auth: AuthentificationService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isValid = await this.auth.isCurrentTokenValid();

    //console.log(`checkTokenValidityAndRefreshIfNeededGuard => navigation demandée vers ${state.url} (token valide: ${isValid})`, route, state);

    if (isValid) {
      return true;
    }

    //console.log("checkTokenValidityAndRefreshIfNeededGuard => regarde si on peut refresh un token existant ...")
    
    let refreshedWithoutIssue = await this.auth.forceTokenRefresh();
    if (refreshedWithoutIssue) {
      return true;
    } else {
      this.router.navigateByUrl('/connexion');
      return false;
    }
  }
}

@Injectable({providedIn: 'root'})
export class hasAdminRoleGuard {
  constructor(private auth: AuthentificationService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let tokenInfo = await this.auth.getCurrentTokenInfo();

    //console.log(`hasAdminRoleGuard => navigation demandée vers ${state.url} (role: ${tokenInfo?.roles})`, route, state);

    if (tokenInfo?.roles.includes(AppRoles.admin)) {
      return true;
    } else {
      this.router.navigateByUrl('/accueil');
      return false;
    }
  }
}
