import { Component } from '@angular/core';

@Component({
  selector: 'app-mon-compte',
  imports: [],
  templateUrl: './mon-compte.component.html',
  styleUrl: './mon-compte.component.scss'
})
export class MonComptePage {

}
