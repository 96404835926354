<header>
    <p>Page d'accueil</p>
</header>
<div class="projets-container">
    @for (p of ListeProjets; track p.id) {
    <app-card-projet [data]=p></app-card-projet>
    }
</div>
<div class="new-projet">
    <p>Nouveau projet</p>
</div>