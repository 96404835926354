import { filter } from "rxjs";
import { Component, signal, linkedSignal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet,NavigationEnd } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { EntiteProjet } from "./Services/EntiteProjet";
import { ProjetService } from "./Services/projet.service";
import { MenusComponent } from './Composants/menus/menus.component';
import { InfosComponent } from './Composants/infos/infos.component';


@Component({
  selector: 'app-root',
  imports: [ CommonModule, RouterOutlet, MatSlideToggleModule, MenusComponent, InfosComponent ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }

  protected showMenu = signal(false);
  protected showInfos = signal(false);
  protected darkMode = signal(false);
  public signalProjetCourant: WritableSignal<EntiteProjet>;

  constructor(private router: Router, private ps: ProjetService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      //console.log(event);
      if (event.url == '/' || event.url.match('/accueil')) {
        this.showMenu.set(true);
        this.showInfos.set(true);
      } else if (event.url.match('/connexion')) {
        this.showMenu.set(false);
        this.showInfos.set(false);
      }
      else {
        this.showMenu.set(true);
        this.showInfos.set(false);
      }
    });
    // exporter le projet courant via la mécanique routerOutletData
    this.signalProjetCourant = linkedSignal({
      source: this.ps.projetCourant,
      computation: (pc) => {
        console.log('~AppComponent => ID de projet récupéré :', pc.id);
        return pc;
      }
    });
  }

  ngOnInit() {
    console.log('AppComponent::ngOnInit', this.router.url);
  }

  toggleMenu() {
    this.showMenu.set(! this.showMenu());
  }

  toggleInfos() {
    this.showInfos.set(! this.showInfos());
  }

  toggleDarkMode() {
    this.darkMode.set(! this.darkMode());
    if (this.darkMode())
      document.body.classList.add('dark-mode');
    else
      document.body.classList.remove('dark-mode');
  }

  majProjetCourant(pc: EntiteProjet) {
    this.signalProjetCourant.set(pc);
  }
}
