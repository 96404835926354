import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

import { AuthentificationService } from '../../Services/authentification.service';
import { environment } from '../../Environments/environment';

@Component({
  selector: 'app-connexion',
  imports: [ CommonModule, FormsModule, MatFormFieldModule, MatInputModule, MatButtonModule ],
  templateUrl: './connexion.component.html',
  styleUrl: './connexion.component.scss'
})
export class ConnexionPage {
  @ViewChild('login') login!: ElementRef;
  @ViewChild('passwd') passwd!: ElementRef;
  VERSION : string = environment.VERSION;

  constructor(private auth: AuthentificationService, private router : Router) {}

  onKeyEnter(event: Event) {
    const login = this.login.nativeElement.value ? `${this.login.nativeElement.value}` : "";
    const passwd = this.passwd.nativeElement.value ? `${this.passwd.nativeElement.value}` : "";
    if (login != '' && passwd != '')
      this.connexion();
  }

  connexion(){
    let login = this.login.nativeElement.value ? `${this.login.nativeElement.value}` : "";
    let passwd = this.passwd.nativeElement.value ? `${this.passwd.nativeElement.value}` : "";

    this.auth.logIn(login, passwd).subscribe();
  }
}
