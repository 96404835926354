import { Component, WritableSignal, linkedSignal, ChangeDetectorRef, signal, ChangeDetectionStrategy, effect } from '@angular/core';
import { NgFor, NgForOf } from '@angular/common';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet, ListeScenarios } from '../../Services/EntiteProjet';
import { MatIconModule } from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';


@Component({
  selector: 'app-projets',
  imports: [MatIconModule, NgFor, NgForOf,MatCardModule, MatChipsModule, MatProgressBarModule],
  templateUrl: './projets.component.html',
  styleUrl: './projets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class ProjetsPage {
trierProjets(arg0: any) {
throw new Error('Method not implemented.');
}
len(arg0: ListeScenarios) {
throw new Error('Method not implemented.');
}
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];
  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
  }
  
  ngOnInit(){
  
    this.ps.listeProjets().then(l => {
      if (l)
        this.ListeProjets = l;
      console.log('ProjetsPage::ngOnInit',this.ListeProjets);
      this.cd.markForCheck();
    });
    // const l = await this.ps.listeProjets();
    // if (l)
    //   this.ListeProjets = l;

  } 
}

