<mat-toolbar>
    <span>ID projet: {{ projetCourant.id }}</span>
    <span class="spacer"></span>
    <button mat-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="changeProjet()">
        Changer de projet <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>
<mat-drawer-container class="container">
    <mat-drawer #detail class="right" position="end" mode="side" [opened]="etatDetail()">
        <p>sidenav détail</p>
    </mat-drawer>

    <div class="main">
        <button type="button" mat-button (click)="toggleDetail()">
            Toggle sidenav
        </button>
    </div>
</mat-drawer-container>
