import { Component, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';

import { NumberInputComponent } from '../../Composants/UI/number-input/number-input.component';

@Component({
  selector: 'app-tests',
  imports: [ CommonModule, NumberInputComponent, MatIconModule, MatButtonModule, FormsModule, MatFormFieldModule ],
  templateUrl: './tests.component.html',
  styleUrl: './tests.component.scss'
})
export class TestsPage {

  _entier: number | null = null;
  private entier = viewChild<NumberInputComponent>('entier');

  updated(val: any) {
    console.log('TestPage:', val);
    if (this.entier()) {
      const e = this.entier()?.getValue();
      if (e)
        this._entier = e;
    }
  }
}
