import { Component } from '@angular/core';

@Component({
  selector: 'app-infos',
  imports: [],
  templateUrl: './infos.component.html',
  styleUrl: './infos.component.scss'
})
export class InfosComponent {

}
