
<mat-card class="card-projet" appearance="outlined">
    <mat-card-header>
    <mat-icon class="header-icon">folder</mat-icon>
      <mat-card-title>{{ data().libelle }}</mat-card-title>
      <mat-card-subtitle>Édité par editeur • dateEdition</mat-card-subtitle>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <mat-icon>dialpad</mat-icon>
          <span>Redial</span>
        </button>
        <button mat-menu-item disabled>
          <mat-icon>voicemail</mat-icon>
          <span>Check voice mail</span>
        </button>
        <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Disable alerts</span>
        </button>
      </mat-menu>
      
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content>

        <div class="headline">
            <mat-icon class="icon-circle">solar_power</mat-icon>
            {{ data().participants }} Producteurs (capacité kWc)
        </div>

        <div class="headline">
            <mat-icon class="icon-circle">power</mat-icon>
            {{ data().nbConsommateurs }} Consommateurs
        </div>

        <div class="headline">
            <mat-icon class="icon-circle">layers</mat-icon>
            {{ data().scenarios }} Scénarios
        </div>

    </mat-card-content>

  </mat-card>