<div class="main-container mat-drawer-content">
    <div id="left-content">
    </div>
    <div id="right-content">
        <p><img src="/assets/logo-light.png" width="30%"/></p>
        <h3>Bienvenue !</h3>
        <p>
            Vous pouvez entrer votre identifiant et<br />
            votre mot de passe pour vous connecter.
        </p>
        <form (keyup.enter)="onKeyEnter($event)">
            <mat-form-field class="example-form-field" appearance="outline">
                <input matInput type="text" value="" label="Email" outline="" required #login>
            </mat-form-field>
            <mat-form-field class="example-form-field" appearance="outline">
                <input matInput id="passwd" type="password" value="" label="Mot de passe" required #passwd>
            </mat-form-field>
            <button MatButton (click)="connexion()" type="submit" color="primary" expand="block">Connexion</button>
        </form>
    </div>
  </div> 
