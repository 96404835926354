import { Component } from '@angular/core';

@Component({
  selector: 'app-creation-projet',
  imports: [],
  templateUrl: './creation-projet.component.html',
  styleUrl: './creation-projet.component.scss'
})
export class CreationProjetPage {

}
