import { timer } from 'rxjs';
import { Component, WritableSignal, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, viewChild, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule} from '@angular/material/sidenav';

import { EntiteProjet } from "../../Services/EntiteProjet";
import { ProjetService } from '../../Services/projet.service';

import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-proj-consommateurs',
  imports: [ MatIconModule, MatButtonModule, MatSidenavModule, MatToolbarModule ],
  templateUrl: './proj-consommateurs.component.html',
  styleUrl: './proj-consommateurs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjConsommateursPage implements OnInit {
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected projetCourant: EntiteProjet;
  // Gestion interface
  protected etatDetail = signal(true);

  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
    this.projetCourant = this.signalProjetCourant();
  }

  ngOnInit() {
    const idProjet = Number(this.activatedRoute.snapshot.paramMap.get('idProjet') as string);
    console.log('ProjConsommateursPage::onInit => idProjet : ' + idProjet);
    if (idProjet < 1) {
      this._parent.majProjetCourant(this.ps.nouveauProjet());
      this.router.navigateByUrl('/accueil');
      return;
    }
    if (this.projetCourant.id != idProjet) {
      this.recupereInfosProjet(idProjet).then(pc => {
        console.log('ProjConsommateursPage::onInit => Projet récupéré =>', pc)
        if (pc) {
          this.projetCourant = pc;
          this._parent.majProjetCourant(pc);
          this.cd.markForCheck();
        }
      });
    }
  }

  afficheDetail(etat: boolean) {
      this.etatDetail.set(etat);
  }

  toggleDetail() {
    this.afficheDetail(! this.etatDetail());
  }

  async changeProjet() {
    const id = (this.projetCourant.id == 73) ? 83 : 73;
    const pc = await this.recupereInfosProjet(id);
      console.log('ProjConsommateursPage::onInit => Projet récupéré =>', pc)
      if (pc) {
        this.projetCourant = pc;
        this._parent.majProjetCourant(pc);
        this.cd.markForCheck();
      }
  }

  // ============== Projets ============================================
  async recupereInfosProjet(idProjet: number) {
    if (idProjet < 1)
      return null;
    const p = await this.ps.detailProjet(idProjet);
    return p;
/*
      // Notifier le signal projetModifie
      this.ps.projetModifie.set(true);
      timer(500).subscribe(() => {
        this.ps.projetModifie.set(false);
      });

      // Vérifier si le nbre de consommateurs est OK
      this.classErreurNbConsommateurs = (this.ps.valideAttributProjet(p, 'nbConsommateurs', p.nbConsommateurs)) ? '' : 'ng-pristine ng-invalid ng-touched ion-pristine ion-invalid ion-touched';
      // Initialiser les Maps
      this.mapCourbes.clear();
      p.courbes.forEach(c => this.mapCourbes.set(c.id, c));
      this.mapDispositifs.clear();
      p.dispositifs.forEach(d => {
        this.mapDispositifs.set(d.id, d);
        //console.log('recupereInfosProjet => dispositif ...', d);
        if (d.type == 'Compteur') {
          this.ps.getCompteur(d.id).then(data => {
            if (data)
              this.mapCompteurs.set(d.id, data);
          });
        }
      });
      this.mapDocuments.clear();
      p.documents.forEach(d => this.mapDocuments.set(d.id, d));
      this.mapParticipants.clear();
      this.nbProducteurs = 0;
      p.participants.forEach(p => {
        this.mapParticipants.set(p.id, p); 
        if (p.producteur && p.producteur.id > 0)
          this.nbProducteurs++;
      });
      this.showToggleOrderingProd = (this.nbProducteurs > 1 && this.currentStepEnergie == 0) ? true : false;
      this.mapScenarios.clear();
      p.scenarios.forEach(s => this.mapScenarios.set(s.id, s));
      
      p.variables.forEach(v => {
        this.lien_sharepoint = (v.variable == "URL_sharepoint") ? v.valeur : this.lien_sharepoint;
        this.lien_odoo = (v.variable == "URL_odoo") ? v.valeur : this.lien_odoo;
      })

      timer(500).subscribe(() => {
        this.ps.projetModifie.set(false);
        console.log("projetModifie => FALSE");
      });
    }
*/
  }
  
}
