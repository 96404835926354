import { Component, WritableSignal, linkedSignal, ChangeDetectorRef, signal, ChangeDetectionStrategy, effect } from '@angular/core';

import { NgFor, NgForOf } from '@angular/common';
import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet } from '../../Services/EntiteProjet';
import { MatIconModule } from '@angular/material/icon';



@Component({
  selector: 'app-menus',
  imports: [MatIconModule,NgForOf],
  templateUrl: './menus.component.html',
  styleUrl: './menus.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MenusComponent {
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];

  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
    this.signalProjetCourant = this._parent.signalProjetCourant;
  }
ngOnInit(){
  
  this.ps.listeProjets().then(l => {
    if (l)
      this.ListeProjets = l;
  });
  // const l = await this.ps.listeProjets();
  // if (l)
  //   this.ListeProjets = l;
  // console.log('MenusComponent::ngOnInit',this.ListeProjets);
}
}


