import { Component, ElementRef, input, output, viewChild } from '@angular/core';
import { formatNumber } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-number-input',
  imports: [ FormsModule, MatFormFieldModule, MatInputModule ],
  templateUrl: './number-input.component.html',
  styleUrl: './number-input.component.scss'
})
export class NumberInputComponent {
  public precision = input.required<number>();
  public value = input.required<string | number>();
  public fixed = input.required<number>();
  public label = input<string>();
  public _value: string = '';
  public onUpdated = output<number>();

  private input = viewChild<ElementRef>('saisie');
  private formateurNombreFr;

  constructor() {
    this.formateurNombreFr = new Intl.NumberFormat('fr-FR');
  }

  ngOnInit() : void {
    this._value = this.formatteNombre(this.value().toString().trim(), true);
  }

  onKeyUp(ev: Event) {
    const value: string = this.input()?.nativeElement.value;
    let s: string = '';
    if (value) {
      if (this.precision() > 0) {
        s = value.replaceAll(/[^0-9,\.\+-]/g, '').replaceAll(',', '.');
        //console.log('onKeyUp 1 =>', value, s);
        if (s.match(/^.*[\.,]$/)) {
          console.log('point terminal détecté', s);
          this._value = s.replaceAll('.', ',');
        }
        else {
          console.log('Valeur à formater :', s);
          this._value = this.formatteNombre(s.replaceAll(/[^0-9,\.\+-]/g, ''), false);
        }
      } else {
        const r = 
        s = value.replaceAll(/[^0-9,\.\+-]/g, '');
        this._value = this.formatteNombre(s, false);
        }
      //console.log('onKeyUp 2 =>', value, this._value);
      const i = this.input();
      if (i && i.nativeElement)
        i.nativeElement.value = this._value;
    }
  }

  onChange(ev: Event) {
    const value: string = this.input()?.nativeElement.value;
    console.log("onChange", value);
    if (value) {
      this._value = this.formatteNombre(this._value, true);
      this.onUpdated.emit(this.getValue());
    }
  }

  getValue(): number {
    return (this.precision() > 0) ? 
      parseFloat(this._value.replaceAll(',', '.').replaceAll(/[^0-9\.\+-]/g, '')) : 
      parseInt(this._value.replaceAll(/[^0-9\+-]/g, ''));
  }

  private formatteNombre(valeur: string, definitive: boolean): string {
    const format = (definitive) ? this.fixed() + '.' + this.precision() + '-'+this.precision() : '0.0-'+this.precision();
    const val = valeur.replaceAll(',', '.').replaceAll(/[^0-9\.\+-]/g, '');
    console.log('Format appliqué :', definitive, format, valeur, val);
    return (this.precision() > 0) ?
      formatNumber(parseFloat(val), 'fr_FR', format) :
      formatNumber(parseInt(val), 'fr_FR', format);
/*  Remplacé par l'utilisation du pipe.
   // Si la précision est 0, on formate en entier
   if (this.precision() === 0) 
       return this.formateurNombreFr.format(parseInt(valeur)).replace(/\u202f/g, " ");
   // Sinon, on formate avec la précision donnée
   const nombreFormate = parseFloat(valeur).toFixed(this.precision()); // Fixe la précision
   return this.formateurNombreFr.format(parseFloat(nombreFormate)).replace(/\u202f/g, " ");
*/
  }

}
