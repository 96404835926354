import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthentificationService } from './authentification.service';
import { environment } from '../Environments/environment';
import { catchError, firstValueFrom, map, of, throwError } from 'rxjs';
//import { AlertController } from '@ionic/angular';
import { EntiteProjet, TypeProjet, ListeProjets, EntiteScenario, ReponseApi, EntiteBilanEnergetique, EntiteCompteur, EntiteBilanFacturation, EntiteBilanFinancier } from './EntiteProjet';

@Injectable({
  providedIn: 'root'
})
export class ProjetService {
  projetCourant = signal<EntiteProjet>(this.nouveauProjet());
  projetModifie = signal(true);
  participantModifie = signal(false);
  courbeModifiee = signal(-1);
  bilanEnergieModifie = signal(false);
  bilanFinancierModifie = signal(false);
  
  //constructor(private auth: AuthentificationService, private http: HttpClient, private router: Router, private alert: AlertController) {}
  constructor(private auth: AuthentificationService, private http: HttpClient, private router: Router) {}
  // ================= Utilitaires =================================================
  async msgAlerte(titre: string, sujet: string, msg: string, redirige: string | null) {
/*    const al = await this.alert.create({
      header: titre,
      subHeader: sujet,
      message: msg,
      buttons: [{
        text: 'Fermer',
        handler: data => {
          if (redirige)
            this.router.navigateByUrl(redirige);
        }
      }]
    });
    al.present();
*/
  alert(titre + "\n" + sujet + "\n" + "msg");
  }

  async prepareHeaders(typeRequete: 'json' | 'text' | 'blob') {
    let token = await this.auth.getToken();
    let headerDict = {"Authorization": 'Bearer ' + token, "Content-Type": '', "Accept": ''};
    switch (typeRequete) {
      case 'json': 
        headerDict['Content-Type'] = 'application/json';
        headerDict['Accept'] = 'application/json';
        break;
      case 'blob':
        headerDict['Content-Type'] = 'application/octet-stream';
        headerDict['Accept'] = 'application/octet-stream';
        break;
      default:
        headerDict['Content-Type'] = 'text/html';
        headerDict['Accept'] = 'text/html';
    }
    let requestOptions = {                                                                                                                                                                                
      headers: new HttpHeaders(headerDict),  
    };
    return requestOptions;
  }

  // Crée une instance de NumberFormat à réutiliser pour éviter de la recréer à chaque appel
  formateurNombreFr = new Intl.NumberFormat('fr-FR');

    formatteNombre(valeur: string, precision: number, format? : Intl.NumberFormat): string {
      format = (format) ? format : this.formateurNombreFr;
      // Si la précision est 0, on formate en entier
      if (precision === 0) 
          return format.format(parseInt(valeur)).replace(/\u202f/g, " ");
      

      // Sinon, on formate avec la précision donnée
      const nombreFormate = parseFloat(valeur).toFixed(precision); // Fixe la précision
      return format.format(parseFloat(nombreFormate)).replace(/\u202f/g, " ");
  }

  // formatteNombre(valeur : string, precision: number): string {
  //   return (precision == 0) ? 
  //     new Intl.NumberFormat('fr-FR').format(parseInt(valeur)).replace(/\u202f/g," ") :
  //     new Intl.NumberFormat('fr-FR').format(parseFloat(Number(valeur).toFixed(precision))).replace(/\u202f/g," ");
  // }

  // ================= Projets =====================================================
  nouveauProjet() : EntiteProjet {
    const p =  <EntiteProjet>{
      id: -1,
      libelle: '',
      type: TypeProjet.VT,
      nbConsommateurs: 0,
      scenarios: [],
      documents: [],
      dispositifs: [],
      participants: [],
      courbes: [],
      user : {
        id : -1,
        nom : '',
        prenom : ''
      },
      variables : []
    };
    return p;
  }

  async supprimeScenario(idScenario: number): Promise<boolean> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.delete<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}`, requestOptions));
    if (res.statut.toUpperCase() == 'OK') {
      this.projetModifie.set(true);
      return true;
    }
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return false;
  }

  async creerProjet (data : string | undefined): Promise<number> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.post<ReponseApi>(`${environment.apiHost}api/projet`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse.id;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return -1;
  }

  async listeProjets() : Promise<ListeProjets| null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<ReponseApi>(`${environment.apiHost}api/projet`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async detailProjet(idProjet: number): Promise<EntiteProjet| null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<ReponseApi>(`${environment.apiHost}api/projet/${idProjet}`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async majDataProjet(idProjet: number, data: object): Promise<EntiteProjet| null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.put<ReponseApi>(`${environment.apiHost}api/projet/${idProjet}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  valideAttributProjet(p: EntiteProjet, attr: string, val : any): boolean {
    switch(attr) {
      case 'libelle':
        if (val == '')
          return false;
        break;
      case 'type':
        if (! (Object.keys(TypeProjet) as string[]).includes(val) )
          return false;
        break;
      case 'nbConsommateurs' :
        const n = Number.parseInt(val);
        switch(p.type as string) {
          case 'ACC':
            if (n < 1)
              return false;
            break;
          case 'ACI':
            if (n < 1 || n > 1)
              return false;
            break;
          case 'ACT':
            if (n < 1 || n > 1)
              return false;
            break;
          case 'VT':
            if (n > 1)
              return false;
            break;
        }
        break;
        case 'URL_sharepoint':
        case 'URL_odoo':
          if (val == '')
            return false;
        break;
        default: 
        console.error('ProjetService::valideAttributProjet => attribut ' + attr + ' non traité !');
        return true;
    }
    return true;
  }

  valideProjet(p: EntiteProjet): string { // Retourne l'un des états StepperState : 'number' | 'edit' | 'done' | 'error'
    if (! this.valideAttributProjet(p, 'libelle', p.libelle))
      return 'error';
    if (! this.valideAttributProjet(p, 'type', p.type))
      return 'error';
    if (! this.valideAttributProjet(p, 'nbConsommateurs', p.nbConsommateurs))
      return 'error';
    return 'done';
  }

  valideEtapeParticipantsProjet(p: EntiteProjet): string {
    let res = 'done';
    return res;
  }

  valideEtapeScenarioEnergie(p: EntiteProjet): string {
    return 'done';
  }

  valideEtapeBilan(p: EntiteProjet): string {
    let calculs = true;
    p.scenarios.forEach(sc => {
      if (! sc.bilanEnergetique || sc.bilanEnergetique.date == '')
        calculs = false;
    })
    return calculs ? 'done' : 'edit';
  }

  valideEtapeScenarioFinancier(p: EntiteProjet): string {
    return 'done';
  }

  async supprimeProjet(idProjet: number): Promise<boolean> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.delete<ReponseApi>(`${environment.apiHost}api/projet/${idProjet}`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return true;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return false;
  }

  // ================= Scénarios ================================================
  valideAttributScenario(attr: string, val: any) {
    switch(attr) {
      case 'libelle':
        if (val == '' || val.length < 2 || val.length > 40)
          return false;
          break;
      case 'repartitionProduction':
        if (val == '')
          return false;
        break;
      case 'repartitionConsommation':
        if (val == '')
          return false;
        break;
      default:
        console.error('ProjetService::valideAttributScenario => attribut ' + attr + ' non traité !');
        return true;
    }
    return true;
  }

  valideScenariot(sc: EntiteScenario): boolean {
    if (! this.valideAttributScenario('libelle', sc.libelle))
      return false;
    if (! this.valideAttributScenario('repartitionProduction', sc.repartitionProduction))
      return false;
    if (! this.valideAttributScenario('repartitionConsommation', sc.repartitionConsommation))
      return false;
    return true;
  }

  async majDataScenario(idProjet: number, idScenario: number, data: object): Promise<EntiteScenario | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.put<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  valideAttributScenarioParticipant(attr: string, val: any) {
    switch(attr) {
      case 'pcSouhait':
        if (parseFloat(val) < 1)
          return false;
        break;
      case 'generateur':
        if (parseInt(val) < 1)  // TODO : vérifier l'existence de l'ID du générateur dans le projet ...
          return false;
        break;
      default:
        console.error('ProjetService::valideAttributScenario => attribut ' + attr + ' non traité !');
        return true;
    }
    return true;
  }

  async majDataScenarioProducteur(idScenario: number, idParticipant: any, data: any): Promise<EntiteScenario | null> {
    const requestOptions = await this.prepareHeaders('json');
    // console.log("Appel API " + `${environment.apiHost}api/scenario/${idScenario}/producteur/${idParticipant}` + " avec ", data);
    const res = await firstValueFrom(this.http.put<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/producteur/${idParticipant}`, data, requestOptions));
    // console.log(res);
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async majDataScenarioConsommateur(idScenario: number, idParticipant: any, data: any): Promise<EntiteScenario | null> {
    const requestOptions = await this.prepareHeaders('json');
    // console.log("Appel API " + `${environment.apiHost}api/scenario/${idScenario}/consommateur/${idParticipant}` + " avec ", data);
    const res = await firstValueFrom(this.http.put<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/consommateur/${idParticipant}`, data, requestOptions));
    // console.log(res);
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async majDataScenarioDispositif(idScenario: number, idDispositif: any, data: any): Promise<EntiteScenario | null> {
    const requestOptions = await this.prepareHeaders('json');
    //console.log("majDataScenarioDispositif => Appel API " + `${environment.apiHost}api/scenario/${idScenario}/${idDispositif}` + " avec ", data);
    const res = await firstValueFrom(this.http.put<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/dispositif/${idDispositif}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async lanceCalculBilanEnergetique(idScenario: number): Promise<EntiteBilanEnergetique | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/calcul/bilan-energetique`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async lanceCalculBilanFacturation(idScenario: number): Promise<EntiteBilanFacturation | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/calcul/bilan-facturation`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async lanceCalculBilanFinancier(idScenario: number): Promise<EntiteBilanFinancier | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<ReponseApi>(`${environment.apiHost}api/scenario/${idScenario}/calcul/bilan-financier`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  // ================= Participants =============================================
  async majDataParticipant(idParticipant: number, data: object): Promise<any | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.put<any>(`${environment.apiHost}api/participant/${idParticipant}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  valideAttributParticipant(attr: string, val : any): boolean {
    switch(attr) {
      case 'nom':
        if (val == '' || val.length < 2 || val.length > 40)
          return false;
        break;
      case 'puissance_souhaitee' :
        const v = parseFloat(val);
        if (v < 1)
          return false;
        break;
      default: 
        console.error('ProjetService::valideAttributParticipant => attribut ' + attr + ' non traité !');
        return true;
    }
    return true;
  }

  async supprimeParticipant(idParticipant: number): Promise<boolean> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.delete<any>(`${environment.apiHost}api/participant/${idParticipant}`, requestOptions));
    console.log(res);
    if (res.statut.toUpperCase() == 'OK') {
      this.projetModifie.set(true);
      return true;
    }
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return false;
  } 

  // ================= Générateurs ==============================================
  async majDataGenerateur(idGenerateur: number, data: object): Promise<any | null> {
    const requestOptions = await this.prepareHeaders('json');
    //console.log(`${environment.apiHost}api/dispositif/${idGenerateur}`, data);
    return await firstValueFrom(this.http.put<any>(`${environment.apiHost}api/dispositif/${idGenerateur}`, data, requestOptions));
  }

  valideAttributGenerateur(attr: string, val : any): boolean {
    switch(attr) {
      case 'puissance':
        const n = Number.parseInt(val);
        if (n < 1)
          return false;
        break;
      default: return true;
    }
    return true;
  }

  valideGenerateur(name : string, val : any): boolean {
    if (! this.valideAttributGenerateur(name, val))
      return false;
    return true;
  }

  // ================= Compteurs ================================================

  async getCompteur(idCompteur: number): Promise<EntiteCompteur | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res =  await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/dispositif/${idCompteur}`, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  valideAttributCompteur(attr: string, val : any): boolean {
    switch(attr) {
      case 'puissance':
        const n = Number.parseInt(val);
        if (n < 1)
          return false;
        break;
      default: return true;
    }
    return true;
  }

  async majDataCompteur(idCompteur: number, data: EntiteCompteur): Promise<EntiteCompteur | null> {
    const requestOptions = await this.prepareHeaders('json');
    //console.log(`${environment.apiHost}api/dispositif/${idGenerateur}`, data);
    const res = await firstValueFrom(this.http.put<any>(`${environment.apiHost}api/dispositif/${idCompteur}`, data, requestOptions));
    if (res.statut.toUpperCase() == 'OK')
      return res.reponse;
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  // ================= Documents ================================================
  async supprimeDocument(idDoc: number): Promise<boolean> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.delete<any>(`${environment.apiHost}api/document/${idDoc}`, requestOptions));
    console.log(res);
    if (res.statut.toUpperCase() == 'OK') {
      this.projetModifie.set(true);
      return true;
    }
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return false;
  } 

  // ================= Courbes ================================================
  async donneesCourbe(idCourbe: number, periode?: 'day' | 'hour' | 'month', details?: boolean): Promise<ReponseApi | null> {
    const requestOptions = await this.prepareHeaders('json');
    console.log('donneesCourbe', periode, details);
    if (! periode)
      periode = 'month';
    if (! details)
      details = true;
    const strDetails: string = (details) ? 'all' : 'light';
    const res = await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/courbe/${idCourbe}/${periode}/${strDetails}`, requestOptions));
    console.log(res);
    if (res.statut.toUpperCase() == 'OK') {
      return res.reponse[0];
    }
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  } 
  
  async donneesCourbeHeatmap(idCourbe: number): Promise<ReponseApi | null> {
    const requestOptions = await this.prepareHeaders('json');
    const res = await firstValueFrom(this.http.get<any>(`${environment.apiHost}api/courbe/${idCourbe}/hour/light`, requestOptions));
    console.log(res);
    if (res.statut.toUpperCase() == 'OK') {
      return res.reponse[0];
    }
    else
      this.msgAlerte('Erreur API', (res.message) ? res.message : '', (res.details) ? res.details : '', null);
    return null;
  }

  async recupereDataCsv(idDispositif: number): Promise<Blob | null> {
    const requestOptions = await this.prepareHeaders('blob');
    const res = await firstValueFrom(this.http.get(`${environment.apiHost}api/dispositif/${idDispositif}/export`, { headers: requestOptions.headers, responseType: 'blob' }))
    if (res && res.size > 0)
      return res;
    return null;
  }
}