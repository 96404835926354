import { ApplicationConfig, LOCALE_ID, importProvidersFrom, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr, 'fr');
import { routes } from './app.routes';
import { ApiErrorInterceptor } from './Intercepteurs/http-error-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideExperimentalZonelessChangeDetection(), 
    provideRouter(routes), 
    provideAnimationsAsync(),
    provideHttpClient(),
    provideHttpClient(withInterceptors([ApiErrorInterceptor])),
    importProvidersFrom(JwtModule.forRoot({})), provideAnimationsAsync(),
  ]
};