<p>Affichage d'un nombre pipé => {{ 10000.114 | number : '1.0-3' }}</p>
<p>
    <!--
    <mat-form-field class="example-form-field">
        
        
    </mat-form-field>
    <mat-form-field class="example-form-field">
        <mat-label>Précision 2 :</mat-label>
        
    </mat-form-field>
    -->
<p>
    <app-number-input #entier label="Entier sur 3 digits" [fixed]=3 [precision]=0 [value]="12" (onUpdated)="updated($event)"></app-number-input>
    @if (_entier) { <br /> Valeur saisie : {{ _entier }} ! }
</p>
<p>
    <app-number-input #p2 label="Fixe 3 | Précision 2" [fixed]=3 [precision]=2 [value]="8.2547" (onUpdated)="updated($event)"></app-number-input>
</p>