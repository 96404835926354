@if (signalProjetCourant().id > 1) {
    <p>Projet courant : {{ signalProjetCourant().id }}</p>
    <section class="navbar">
        <div class="titre">Navigation</div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>solar_power</mat-icon>
                Producteurs
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>bolt</mat-icon>
                Consommateurs
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>description</mat-icon>
                Configuration
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z"/></svg>
                Scénarios
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>assignment_turned_in</mat-icon>
                Bilan
              </button>
            </a>
          </div>
        </section>
        <span layout="row"><hr flex/></span>

        
        <div class="button-nav" *ngFor="let p of ListeProjets; index as i">
          <a href="/projet/{{p.id}}/producteurs">{{p.libelle}}</a>
        </div>

        <section>
            <div class="titre">Projets Ouverts</div>
            <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button">
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 1
                  </button>
                </a>
              </div>
              <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button">
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 2
                  </button>
                </a>
              </div>
              <div class="button-nav">
                <a href="">
                  <button mat-flat-button class="custom-button">
                    <mat-icon>folder_open</mat-icon>
                    projets ouverts 3
                  </button>
                </a>
              </div>
        </section>
} @else {
    <section class="navbar">
        <div class="titre">Navigation</div>
          <div class="button-nav">
            <a href="/accueil">
              <button mat-flat-button class="custom-button">
                <mat-icon>home</mat-icon>
                Page d'accueil
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="/projets">
              <button mat-flat-button class="custom-button">
                <mat-icon>folder</mat-icon>
                Mes projets
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>description</mat-icon>
                TURPE et Tarif d'achat
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>bar_chart</mat-icon>
                Base de données
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>account_circle</mat-icon>
                Mon compte
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="">
              <button mat-flat-button class="custom-button">
                <mat-icon>help_outline</mat-icon>
                Docs et Tutos
              </button>
            </a>
          </div>
          <div class="button-nav">
            <a href="/projet/73/producteurs">
              <button mat-flat-button class="custom-button">
                <mat-icon>info</mat-icon>
                À propos
              </button>
            </a>
          </div>
        </section>
}
