<div class="main-container mat-drawer-content">
  @if (showMenu()) {
  <div id="left-content">
    <img src="/assets/logo-dark.png">
    <div id="mode-button">
      <mat-slide-toggle [checked]="darkMode()" (change)="toggleDarkMode()">
        @if (darkMode()) { dark mode } @else { light mode }
      </mat-slide-toggle>
    </div>
    <app-menus></app-menus>
  </div>
  }
  <div id="center-content">
  <!-- <router-outlet [routerOutletData]="signalProjetCourant()"/> -->
    <router-outlet />
  </div>
  @if (showInfos()) {
  <div id="right-content">
    <app-infos></app-infos>
  </div>
  }
</div> 