<mat-toolbar>
    <span>ID projet: {{ projetCourant.id }}</span>
    <span class="spacer"></span>
    <button mat-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="changeProjet()">
        Changer de projet <mat-icon>autorenew</mat-icon>
    </button>
</mat-toolbar>
<div class="main-container">
    <div id="left-content">
        Liste des producteurs
    </div>
    <div id="right-content">
        liste des consommateurs
    </div>
</div>
