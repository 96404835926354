import { Component, WritableSignal, linkedSignal, ChangeDetectorRef, signal, ChangeDetectionStrategy, effect, input, OnInit, AfterViewChecked, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';

import { AppComponent } from '../../app.component';
import { ProjetService } from '../../Services/projet.service';
import { EntiteProjet, ListeScenarios } from '../../Services/EntiteProjet';

@Component({
  selector: 'app-card-projet',
  imports: [MatIconModule, MatCardModule, MatChipsModule, MatProgressBarModule,MatMenuModule, CommonModule,MatDividerModule],
  templateUrl: './card-projet.component.html',
  styleUrl: './card-projet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CardProjetComponent implements OnInit {

  public data = input.required<EntiteProjet>();

  trierProjets(arg0: any) {
    throw new Error('Method not implemented.');
  }
  len(arg0: ListeScenarios) {
    throw new Error('Method not implemented.');
  }
  public signalProjetCourant: WritableSignal<EntiteProjet>;
  protected ListeProjets: EntiteProjet[] = [];
  constructor(private _parent: AppComponent, private cd: ChangeDetectorRef, protected ps: ProjetService) {
      this.signalProjetCourant = this._parent.signalProjetCourant;
  }
    
  ngOnInit(){
    //console.log('CardProjetComponent::ngOnInit => data :', this.data());
  }

}